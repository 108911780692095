import * as regexp from 'lib/regexp';

export const replaceQuotes = (value: string | undefined) =>
  value?.replaceAll('\'', '"');

const EnvVars = {
  // Treasure added env vars
  NEXT_PUBLIC_TREASURE_PORTAL_URL: process.env.NEXT_PUBLIC_TREASURE_PORTAL_URL,
  NEXT_PUBLIC_TREASURE_HAS_FAUCET: process.env.NEXT_PUBLIC_TREASURE_HAS_FAUCET,
  // Blockscout env vars
  NEXT_PUBLIC_NETWORK_ICON_DARK: process.env.NEXT_PUBLIC_NETWORK_ICON_DARK,
  NEXT_PUBLIC_GIT_TAG: process.env.NEXT_PUBLIC_GIT_TAG,
  NEXT_PUBLIC_HOMEPAGE_CHARTS: process.env.NEXT_PUBLIC_HOMEPAGE_CHARTS,
  NEXT_PUBLIC_USE_NEXT_JS_PROXY: process.env.NEXT_PUBLIC_USE_NEXT_JS_PROXY,
  NEXT_PUBLIC_API_HOST: process.env.NEXT_PUBLIC_API_HOST,
  NEXT_PUBLIC_API_WEBSOCKET_PROTOCOL:
    process.env.NEXT_PUBLIC_API_WEBSOCKET_PROTOCOL,
  NEXT_PUBLIC_PROMOTE_BLOCKSCOUT_IN_TITLE:
    process.env.NEXT_PUBLIC_PROMOTE_BLOCKSCOUT_IN_TITLE,
  NEXT_PUBLIC_NETWORK_ID: process.env.NEXT_PUBLIC_NETWORK_ID,
  NEXT_PUBLIC_NETWORK_CURRENCY_SYMBOL:
    process.env.NEXT_PUBLIC_NETWORK_CURRENCY_SYMBOL,
  NEXT_PUBLIC_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_GIT_COMMIT_SHA,
  NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID:
    process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
  NEXT_PUBLIC_APP_HOST: typeof window !== 'undefined' ? window.location.hostname : process.env.NEXT_PUBLIC_APP_HOST,
  NEXT_PUBLIC_NETWORK_RPC_URL: process.env.NEXT_PUBLIC_NETWORK_RPC_URL,
  NEXT_PUBLIC_APP_PORT: process.env.NEXT_PUBLIC_APP_PORT,
  NEXT_PUBLIC_APP_INSTANCE: process.env.NEXT_PUBLIC_APP_INSTANCE,
  NEXT_PUBLIC_NETWORK_CURRENCY_DECIMALS:
    process.env.NEXT_PUBLIC_NETWORK_CURRENCY_DECIMALS,
  NEXT_PUBLIC_IS_ACCOUNT_SUPPORTED:
    process.env.NEXT_PUBLIC_IS_ACCOUNT_SUPPORTED,
  NEXT_PUBLIC_NETWORK_NAME: process.env.NEXT_PUBLIC_NETWORK_NAME,
  NEXT_PUBLIC_NETWORK_SHORT_NAME: process.env.NEXT_PUBLIC_NETWORK_SHORT_NAME,
  NEXT_PUBLIC_NETWORK_ICON: process.env.NEXT_PUBLIC_NETWORK_ICON,
  NEXT_PUBLIC_API_PROTOCOL: process.env.NEXT_PUBLIC_API_PROTOCOL,
  NEXT_PUBLIC_IS_TESTNET: process.env.NEXT_PUBLIC_IS_TESTNET,
  NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_NETWORK_LOGO: process.env.NEXT_PUBLIC_NETWORK_LOGO,
  NEXT_PUBLIC_HIDE_INDEXING_ALERT_BLOCKS:
    process.env.NEXT_PUBLIC_HIDE_INDEXING_ALERT_BLOCKS,
  NEXT_PUBLIC_APP_PROTOCOL: process.env.NEXT_PUBLIC_APP_PROTOCOL,
  NEXT_PUBLIC_API_SPEC_URL: process.env.NEXT_PUBLIC_API_SPEC_URL,
  NEXT_PUBLIC_AD_BANNER_PROVIDER: process.env.NEXT_PUBLIC_AD_BANNER_PROVIDER,
  NEXT_PUBLIC_NETWORK_CURRENCY_NAME:
    process.env.NEXT_PUBLIC_NETWORK_CURRENCY_NAME,
  NEXT_PUBLIC_NETWORK_LOGO_DARK: process.env.NEXT_PUBLIC_NETWORK_LOGO_DARK,
  NEXT_PUBLIC_NAVIGATION_HIDDEN_LINKS:
    process.env.NEXT_PUBLIC_NAVIGATION_HIDDEN_LINKS,
  NEXT_PUBLIC_OTHER_LINKS: process.env.NEXT_PUBLIC_OTHER_LINKS,
  NEXT_PUBLIC_FEATURED_NETWORKS: process.env.NEXT_PUBLIC_FEATURED_NETWORKS,
  NEXT_PUBLIC_FOOTER_LINKS: process.env.NEXT_PUBLIC_FOOTER_LINKS,
  NEXT_PUBLIC_HOMEPAGE_PLATE_BACKGROUND:
    process.env.NEXT_PUBLIC_HOMEPAGE_PLATE_BACKGROUND,
  NEXT_PUBLIC_HOMEPAGE_PLATE_TEXT_COLOR:
    process.env.NEXT_PUBLIC_HOMEPAGE_PLATE_TEXT_COLOR,
  NEXT_PUBLIC_HOMEPAGE_SHOW_AVG_BLOCK_TIME:
    process.env.NEXT_PUBLIC_HOMEPAGE_SHOW_AVG_BLOCK_TIME,
  NEXT_PUBLIC_HIDE_INDEXING_ALERT_INT_TXS:
    process.env.NEXT_PUBLIC_HIDE_INDEXING_ALERT_INT_TXS,
  NEXT_PUBLIC_MAINTENANCE_ALERT_MESSAGE:
    process.env.NEXT_PUBLIC_MAINTENANCE_ALERT_MESSAGE,
  NEXT_PUBLIC_NETWORK_EXPLORERS: process.env.NEXT_PUBLIC_NETWORK_EXPLORERS,
  NEXT_PUBLIC_CONTRACT_CODE_IDES: process.env.NEXT_PUBLIC_CONTRACT_CODE_IDES,
  NEXT_PUBLIC_HAS_CONTRACT_AUDIT_REPORTS:
    process.env.NEXT_PUBLIC_HAS_CONTRACT_AUDIT_REPORTS,
  NEXT_PUBLIC_API_PORT: process.env.NEXT_PUBLIC_API_PORT,
  NEXT_PUBLIC_API_BASE_PATH: process.env.NEXT_PUBLIC_API_BASE_PATH,
  NEXT_PUBLIC_VIEWS_ADDRESS_HIDDEN_VIEWS: process.env.NEXT_PUBLIC_VIEWS_ADDRESS_HIDDEN_VIEWS,
  NEXT_PUBLIC_VIEWS_ADDRESS_IDENTICON_TYPE: process.env.NEXT_PUBLIC_VIEWS_ADDRESS_IDENTICON_TYPE,
  NEXT_PUBLIC_VIEWS_CONTRACT_SOLIDITYSCAN_ENABLED: process.env.NEXT_PUBLIC_VIEWS_CONTRACT_SOLIDITYSCAN_ENABLED,
  NEXT_PUBLIC_OG_DESCRIPTION: process.env.NEXT_PUBLIC_OG_DESCRIPTION,
  NEXT_PUBLIC_OG_IMAGE_URL: process.env.NEXT_PUBLIC_OG_IMAGE_URL,
  NEXT_PUBLIC_NETWORK_CURRENCY_WEI_NAME: process.env.NEXT_PUBLIC_NETWORK_CURRENCY_WEI_NAME,
  NEXT_PUBLIC_NETWORK_GOVERNANCE_TOKEN_SYMBOL: process.env.NEXT_PUBLIC_NETWORK_GOVERNANCE_TOKEN_SYMBOL,
  NEXT_PUBLIC_NETWORK_VERIFICATION_TYPE: process.env.NEXT_PUBLIC_NETWORK_VERIFICATION_TYPE,
  NEXT_PUBLIC_RE_CAPTCHA_APP_SITE_KEY: process.env.NEXT_PUBLIC_RE_CAPTCHA_APP_SITE_KEY,
  NEXT_PUBLIC_VIEWS_BLOCK_HIDDEN_FIELDS: process.env.NEXT_PUBLIC_VIEWS_BLOCK_HIDDEN_FIELDS,
  NEXT_PUBLIC_VIEWS_NFT_MARKETPLACES: process.env.NEXT_PUBLIC_VIEWS_NFT_MARKETPLACES,
  NEXT_PUBLIC_VIEWS_TX_HIDDEN_FIELDS: process.env.NEXT_PUBLIC_VIEWS_TX_HIDDEN_FIELDS,
  NEXT_PUBLIC_VIEWS_TX_ADDITIONAL_FIELDS: process.env.NEXT_PUBLIC_VIEWS_TX_ADDITIONAL_FIELDS,
  NEXT_PUBLIC_AUTH_URL: process.env.NEXT_PUBLIC_AUTH_URL,
  NEXT_PUBLIC_LOGOUT_URL: process.env.NEXT_PUBLIC_LOGOUT_URL,
  NEXT_PUBLIC_AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  NEXT_PUBLIC_ADMIN_SERVICE_API_HOST: process.env.NEXT_PUBLIC_ADMIN_SERVICE_API_HOST,
  NEXT_PUBLIC_AD_ADBUTLER_CONFIG_MOBILE: process.env.NEXT_PUBLIC_AD_ADBUTLER_CONFIG_MOBILE,
  NEXT_PUBLIC_AD_ADBUTLER_CONFIG_DESKTOP: process.env.NEXT_PUBLIC_AD_ADBUTLER_CONFIG_DESKTOP,
  NEXT_PUBLIC_AD_BANNER_ADDITIONAL_PROVIDER: process.env.NEXT_PUBLIC_AD_BANNER_ADDITIONAL_PROVIDER,
  NEXT_PUBLIC_AD_TEXT_PROVIDER: process.env.NEXT_PUBLIC_AD_TEXT_PROVIDER,
  NEXT_PUBLIC_BEACON_CHAIN_CURRENCY_SYMBOL: process.env.NEXT_PUBLIC_BEACON_CHAIN_CURRENCY_SYMBOL,
  NEXT_PUBLIC_HAS_BEACON_CHAIN: process.env.NEXT_PUBLIC_HAS_BEACON_CHAIN,
  NEXT_PUBLIC_BRIDGED_TOKENS_CHAINS: process.env.NEXT_PUBLIC_BRIDGED_TOKENS_CHAINS,
  NEXT_PUBLIC_BRIDGED_TOKENS_BRIDGES: process.env.NEXT_PUBLIC_BRIDGED_TOKENS_BRIDGES,
  NEXT_PUBLIC_DATA_AVAILABILITY_ENABLED: process.env.NEXT_PUBLIC_DATA_AVAILABILITY_ENABLED,
  NEXT_PUBLIC_GAS_TRACKER_UNITS: process.env.NEXT_PUBLIC_GAS_TRACKER_UNITS,
  NEXT_PUBLIC_GAS_TRACKER_ENABLED: process.env.NEXT_PUBLIC_GAS_TRACKER_ENABLED,
  NEXT_PUBLIC_GOOGLE_ANALYTICS_PROPERTY_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_PROPERTY_ID,
  NEXT_PUBLIC_GRAPHIQL_TRANSACTION: process.env.NEXT_PUBLIC_GRAPHIQL_TRANSACTION,
  NEXT_PUBLIC_GROWTH_BOOK_CLIENT_KEY: process.env.NEXT_PUBLIC_GROWTH_BOOK_CLIENT_KEY,
  NEXT_PUBLIC_MARKETPLACE_ENABLED: process.env.NEXT_PUBLIC_MARKETPLACE_ENABLED,
  NEXT_PUBLIC_MARKETPLACE_CONFIG_URL: process.env.NEXT_PUBLIC_MARKETPLACE_CONFIG_URL,
  NEXT_PUBLIC_MARKETPLACE_SUBMIT_FORM: process.env.NEXT_PUBLIC_MARKETPLACE_SUBMIT_FORM,
  NEXT_PUBLIC_MARKETPLACE_SUGGEST_IDEAS_FORM: process.env.NEXT_PUBLIC_MARKETPLACE_SUGGEST_IDEAS_FORM,
  NEXT_PUBLIC_MARKETPLACE_CATEGORIES_URL: process.env.NEXT_PUBLIC_MARKETPLACE_CATEGORIES_URL,
  NEXT_PUBLIC_MARKETPLACE_SECURITY_REPORTS_URL: process.env.NEXT_PUBLIC_MARKETPLACE_SECURITY_REPORTS_URL,
  NEXT_PUBLIC_METASUITES_ENABLED: process.env.NEXT_PUBLIC_METASUITES_ENABLED,
  NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
  NEXT_PUBLIC_NAME_SERVICE_API_HOST: process.env.NEXT_PUBLIC_NAME_SERVICE_API_HOST,

  NEXT_PUBLIC_ROLLUP_TYPE: process.env.NEXT_PUBLIC_ROLLUP_TYPE,
  NEXT_PUBLIC_ROLLUP_L1_BASE_URL: process.env.NEXT_PUBLIC_ROLLUP_L1_BASE_URL,
  NEXT_PUBLIC_ROLLUP_L2_WITHDRAWAL_URL: process.env.NEXT_PUBLIC_ROLLUP_L2_WITHDRAWAL_URL,

  NEXT_PUBLIC_SAFE_TX_SERVICE_URL: process.env.NEXT_PUBLIC_SAFE_TX_SERVICE_URL,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_ENABLE_TRACING: process.env.NEXT_PUBLIC_SENTRY_ENABLE_TRACING,
  NEXT_PUBLIC_VISUALIZE_API_HOST: process.env.NEXT_PUBLIC_VISUALIZE_API_HOST,
  NEXT_PUBLIC_STATS_API_HOST: process.env.NEXT_PUBLIC_STATS_API_HOST,
  NEXT_PUBLIC_IS_SUAVE_CHAIN: process.env.NEXT_PUBLIC_IS_SUAVE_CHAIN,
  NEXT_PUBLIC_SWAP_BUTTON_URL: process.env.NEXT_PUBLIC_SWAP_BUTTON_URL,
  NEXT_PUBLIC_TRANSACTION_INTERPRETATION_PROVIDER: process.env.NEXT_PUBLIC_TRANSACTION_INTERPRETATION_PROVIDER,
  NEXT_PUBLIC_HAS_USER_OPS: process.env.NEXT_PUBLIC_HAS_USER_OPS,
  NEXT_PUBLIC_VALIDATORS_CHAIN_TYPE: process.env.NEXT_PUBLIC_VALIDATORS_CHAIN_TYPE,
  NEXT_PUBLIC_CONTRACT_INFO_API_HOST: process.env.NEXT_PUBLIC_CONTRACT_INFO_API_HOST,
  NEXT_PUBLIC_WEB3_WALLETS: process.env.NEXT_PUBLIC_WEB3_WALLETS,
  NEXT_PUBLIC_WEB3_DISABLE_ADD_TOKEN_TO_WALLET: process.env.NEXT_PUBLIC_WEB3_DISABLE_ADD_TOKEN_TO_WALLET,
};

if (typeof window !== 'undefined') {
  window.__envs = EnvVars as Record<string, string>;
}

export const getEnvValue = (envName: keyof typeof EnvVars) => {
  return replaceQuotes(EnvVars[envName as keyof typeof EnvVars] as string);
};

export const parseEnvJson = <DataType>(
  env: string | undefined,
): DataType | null => {
  try {
    return JSON.parse(env || 'null') as DataType | null;
  } catch (error) {
    return null;
  }
};

export const getExternalAssetFilePath = (envName: keyof typeof EnvVars) => {
  const parsedValue = getEnvValue(envName);

  if (!parsedValue) {
    return;
  }

  return buildExternalAssetFilePath(envName, parsedValue);
};

export const buildExternalAssetFilePath = (name: string, value: string) => {
  try {
    const fileName = name
      .replace(/^NEXT_PUBLIC_/, '')
      .replace(/_URL$/, '')
      .toLowerCase();
    const url = new URL(value);
    const fileExtension = url.pathname.match(regexp.FILE_EXTENSION)?.[1];
    return `/assets/${ fileName }.${ fileExtension }`;
  } catch (error) {
    return;
  }
};
