import { IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import React from 'react';

import IconSvg from 'ui/shared/IconSvg';

// import SettingsColorTheme from './SettingsColorTheme';
import SettingsIdentIcon from './SettingsIdentIcon';

const Settings = () => {

  return (
    <Popover placement="bottom-start" trigger="hover" >
      <PopoverTrigger >
        <IconButton
          variant="simple"
          colorScheme="blue"
          aria-label="User settings"
          icon={ <IconSvg name="gear_slim" boxSize={ 5 }/> }
          p="1px"
          boxSize={ 5 }
        />
      </PopoverTrigger>
      <PopoverContent overflowY="hidden" w="auto" fontSize="sm">
        <PopoverBody boxShadow="2xl" p={ 4 }>
          { /* <SettingsColorTheme/> */ }
          { /* <Box borderColor="divider" borderWidth="1px" my={ 3 }/> */ }
          <SettingsIdentIcon/>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default React.memo(Settings);
