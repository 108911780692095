// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const colors: Record<string, any> = {
  // TREASURE COLORS
  ruby: {
    "100": "#FCE9E9",
    "200": "#F8D4D4",
    "300": "#F5BEBE",
    "400": "#F1A8A8",
    "500": "#EE9393",
    "600": "#EA7D7D",
    "700": "#E76767",
    "800": "#E35151",
    "900": "#DC2626",
    "1000": "#C62222",
    "1100": "#841717",
  },
  honey: {
    "100": "#FFFDF7",
    "200": "#FFFAEF",
    "300": "#FEF5DF",
    "400": "#FEF0D0",
    "500": "#FDEBC0",
    "600": "#FDE7B0",
    "700": "#FCE2A0",
    "800": "#FCDD90",
    "900": "#FBD881",
    "1000": "#FBD371",
    "1100": "#FACE61",
  },
  night: {
    "100": "#FAFAFA",
    "200": "#E7E8E9",
    "300": "#CFD1D4",
    "400": "#B7BABE",
    "500": "#9FA3A9",
    "600": "#70747D",
    "700": "#404652",
    "800": "#1F2D45",
    "900": "#19253A",
    "1000": "#172135",
    "1100": "#131D2E",
    "1200": "#0D1420",
    "1300": "#0A111C",
  },
  sapphire: {
    "100": "#E9F4FA",
    "200": "#BCDEF1",
    "300": "#79BCE2",
    "400": "#4DA6D9",
    "500": "#369BD4",
    "600": "#2090CF",
    "700": "#1D82BA",
    "800": "#13567C",
    "900": "#104868",
    "1000": "#0A2B3E",
    "1100": "#061D29",
  },
  success: {
    "100": "#F0FFF0",
    "200": "#E1FEE1",
    "300": "#CDFCCD",
    "400": "#B7F2B7",
    "500": "#A0F0A0",
    "600": "#6DEB6D",
    "700": "#4FC74F",
    "800": "#307749",
    "900": "#245134",
    "1000": "#0A421E",
  },
  black: "#0A111C",
  white: "#ffffff",
  blackAlpha: {
    "50": "RGBA(16, 17, 18, 0.04)",
    "100": "RGBA(16, 17, 18, 0.06)",
    "200": "RGBA(16, 17, 18, 0.08)",
    "300": "RGBA(16, 17, 18, 0.16)",
    "400": "RGBA(16, 17, 18, 0.24)",
    "500": "RGBA(16, 17, 18, 0.36)",
    "600": "RGBA(16, 17, 18, 0.48)",
    "700": "RGBA(16, 17, 18, 0.64)",
    "800": "RGBA(16, 17, 18, 0.80)",
    "900": "RGBA(16, 17, 18, 0.92)",
  },

  github: "#171923",
  telegram: "#2775CA",
  linkedin: "#1564BA",
  discord: "#9747FF",
  slack: "#1BA27A",
  twitter: "#000000",
  opensea: "#2081E2",
  facebook: "#4460A0",
  medium: "#231F20",
  reddit: "#FF4500",
};

// transformed to chakra-ui colors
colors.blue = { ...colors.sapphire };
colors.red = {
  ...colors.ruby,
};
colors.green = {
  ...colors.success,
};
colors.gray = {
  ...colors.night,
};

export default colors;
