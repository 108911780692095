import type { FlexProps, GridProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import Tag from 'ui/shared/chakra/Tag';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';
import Settings from 'ui/snippets/topBar/settings/Settings.treasure';

import NetworkLogo from '../networkMenu/NetworkLogo';
import FooterLinkItem from './FooterLinkItem';
import IntTxsIndexingStatus from './IntTxsIndexingStatus';

const Footer = () => {
  const FOOTER_LINKS = [
    {
      icon: 'social/discord' as const,
      iconSize: '24px',
      text: 'Discord',
      url: 'https://discord.com/invite/treasuredao',
    },
    {
      icon: 'social/twitch' as const,
      iconSize: '18px',
      text: 'Twitch',
      url: 'https://www.twitch.tv/playontreasure',
    },
    {
      icon: 'social/twitter' as const,
      iconSize: '18px',
      text: 'Twitter',
      url: 'https://twitter.com/Treasure_DAO',
    },

    {
      icon: 'social/youtube' as const,
      iconSize: '20px',
      text: 'Youtube',
      url: 'https://www.youtube.com/@PlayOnTreasure',
    },
  ];

  const containerProps: FlexProps = {
    as: 'footer',
    px: { base: 4, lg: 12 },
    py: { base: 4, lg: 9 },
    borderTop: '1px solid',
    borderColor: 'divider',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: { base: 'column', sm: 'row' },
  };

  const renderNetworkInfo = React.useCallback((gridArea?: GridProps['gridArea']) => {
    return (
      <Flex
        gridArea={ gridArea }
        flexWrap="wrap"
        flexDirection="column"
        columnGap={ 8 }
        rowGap={ 6 }
        mb={{ base: 5, lg: 10 }}
        _empty={{ display: 'none' }}
      >
        { !config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus/> }
        <div>
          <NetworkAddToWallet/>
        </div>
        <Flex alignItems="end">
          <NetworkLogo isCollapsed={ false }/>
          <Tag variant="outline" size="sm" className="" minH={ 0 } colorScheme="ruby" paddingY={ 1 } fontSize={ 10 }>Ruby Testnet</Tag>
        </Flex>
      </Flex>
    );
  }, []);

  return (
    <Flex
      { ...containerProps }

    >

      { renderNetworkInfo({ lg: 'network' }) }

      <Flex
        gridArea={{ lg: 'links-top' }}
        gap={ 1 }
        alignItems="center"
      >
        { FOOTER_LINKS.map(link => <FooterLinkItem { ...link } key={ link.text }/>) }
        <div>
          <Settings/>
        </div>
      </Flex>
    </Flex>
  );
};

export default React.memo(Footer);
